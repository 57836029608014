import styled from 'styled-components';
import { rem, device } from '../utils';
export var Wrapper = styled.footer.withConfig({
  componentId: "xu3vm5-0"
})(["margin:", " 0 30px;.legal{font-size:12px;margin-top:5px;margin-bottom:15px;a{display:block;color:inherit;text-decoration:none;font-weight:bold;margin:10px 0;&:hover{text-decoration:underline;}}@media ", "{font-size:", ";a{display:inline-block;margin:0;margin-right:20px;&:last-child{margin-right:0;}}}}"], rem(80), device.tablet, rem(12));
export var Container = styled.div.withConfig({
  componentId: "xu3vm5-1"
})(["text-align:center;.copyright{font-size:", ";}.social{margin:0px 0;a{display:inline-block;padding:10px;margin-right:5px;&:last-child{margin-right:0;}}}"], rem(10));
export var Content = styled.div.withConfig({
  componentId: "xu3vm5-2"
})(["margin:40px 7% 20px 7%;padding:", " 20px;border-top:1px solid #E2E2E2;border-bottom:1px solid #E2E2E2;h3{text-transform:uppercase;font-size:", ";}p{line-height:1.3em;font-size:", ";}"], rem(20), rem(19), rem(16));