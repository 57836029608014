// @flow

import React from 'react';

import TitleBorder from '../../images/icons/TitleBorder.svg';

import {
  Wrapper,
} from './Title.styles';

type Props = {
  text: String,
  left: Boolean,
  className: ?String,
}

const Title = (props: Props) => (
  <Wrapper
    className={`${props.left ? 'align-left' : 'align-center'} ${props.className || ''}`.trim()}
  >
    <h3 dangerouslySetInnerHTML={{ __html: props.text}} />
    <TitleBorder />
  </Wrapper>
);

export default Title;
