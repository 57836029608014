// @flow

import React from 'react';

import Brand from './common/Brand';

import Facebook from '../images/icons/Facebook.svg';
import Instagram from '../images/icons/Instagram.svg';

import { OutboundLink } from '../utils';

import {
  Wrapper,
  Container,
  Content,
} from './Footer.styles';

const { MAUXDEDOS } = process.env;

const Footer = () => (
  <Wrapper>
    <Container
      className="container"
    >
      <Brand />
      <Content>
        <h3>Une question ?</h3>
        <p>
          Contactez-nous au <strong>01 80 96 38 70</strong><br />
          si vous appelez depuis la France<br /><br />
          (appel non surtaxé)
        </p>
      </Content>
      <div className="social">
        <OutboundLink
          href="https://www.facebook.com/LeChampDeFleurs/"
          target="_blank"
          rel="noopener noreferrer"
          alt="Facebook"
          eventAction="clic-reseaux-sociaux"
          eventLabel="clic-LS-RS-facebook"
        >
          <Facebook />
        </OutboundLink>
        <OutboundLink
          href="https://www.instagram.com/champdefleurs_officiel/"
          target="_blank"
          rel="noopener noreferrer"
          alt="Instagram"
          eventAction="clic-reseaux-sociaux"
          eventLabel="clic-LS-RS-Instagram"
        >
          <Instagram width="20px" height="20px" />
        </OutboundLink>
      </div>
      <div className="legal">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${MAUXDEDOS ? 'https://www.lesmauxdedos.com/contact' : 'https://www.champdefleurs.fr/contacter'}`}
        >
          Nous contacter
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${MAUXDEDOS ? 'https://www.lesmauxdedos.com/mentions-legales' : 'https://www.champdefleurs.fr/mentions-legales'}`}
        >
          Mentions Legales
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`${MAUXDEDOS ? 'https://www.lesmauxdedos.com/cgv' : 'https://www.champdefleurs.fr/conditions-generales'}`}
        >
          Conditions Générales
        </a>
      </div>
      <div className="copyright">Copyrights © {new Date().getFullYear()} All Rights Reserved. {MAUXDEDOS ? 'Les Maux de Dos' : 'Champ de Fleurs'}.</div>
    </Container>
  </Wrapper>
);

export default Footer;
