import styled from 'styled-components';
import { rem, device } from '../../../utils';
export var Wrapper = styled.div.withConfig({
  componentId: "sc-1q4k2lz-0"
})([""]);
export var Container = styled.div.withConfig({
  componentId: "sc-1q4k2lz-1"
})(["color:#2B2B2B;"]);
export var Gradient = styled.div.withConfig({
  componentId: "sc-1q4k2lz-2"
})(["padding:", " 0 20px;background:linear-gradient(180deg,rgba(245,245,245,1) 0%,rgba(255,255,255,1) 100%);"], rem(80));
export var CartWrapper = styled.div.withConfig({
  componentId: "sc-1q4k2lz-3"
})(["display:flex;flex-direction:column;border-radius:14px;background:#F5F5F5;border:2px solid #F5F5F5;box-shadow:0 16px 15px -2px rgba(240,240,240,.3);margin-bottom:50px;@media ", "{flex-direction:row;box-shadow:0 18px 17px -2px rgba(240,240,240,.5);}"], device.laptop);
export var CartProductCol = styled.div.withConfig({
  componentId: "sc-1q4k2lz-4"
})(["flex:1;border-top-left-radius:14px;border-top-right-radius:14px;background:#FFF;padding:20px;display:flex;flex-direction:column-reverse;@media ", "{display:flex;flex-direction:column;border-radius:0;border-top-left-radius:14px;border-bottom-left-radius:14px;}.product-image-container{width:100%;text-align:center;position:relative;.loader{position:absolute;top:0;left:0;right:0;bottom:0;}}.product-image{width:100%;max-width:380px;margin:0px auto;position:relative;z-index:1;&.hide{display:none!important;}&.active{display:block!important;}img{width:100%;height:auto!important;margin-top:0;}}.reinsurance{list-style:none;padding:0;margin:0 0px 15px 0px;li{display:inline-block;text-align:center;width:33%;vertical-align:top;padding:0 10px;box-sizing:border-box;line-height:0.9em;svg{height:40px;}@media ", "{line-height:1.1em;svg{height:50px;}}}h4{text-transform:uppercase;font-size:", ";margin-bottom:0;@media ", "{font-size:", ";margin-bottom:10px;}}p{font-size:", ";margin:0;display:none;@media ", "{display:block;}}}"], device.laptop, device.laptop, rem(10), device.laptop, rem(15), rem(14), device.laptop);
export var CartOptionsCol = styled.div.withConfig({
  componentId: "sc-1q4k2lz-5"
})(["flex:1;padding:", " 20px;display:flex;flex-direction:column;@media ", "{padding:", " 5% ", " 5%;align-content:space-around;}.description,.options{flex:1 0 auto;}.description{h4{margin-top:0;font-size:", ";}p,ul{font-size:", ";line-height:1.4em;}}.keypoints{list-style:none;margin-top:30px;padding:0;@media ", "{display:none;}li{display:inline-block;vertical-align:middle;width:49.9%;margin-bottom:15px;.icon{width:40px;&.ue{transform:translateX(-5px);}}span{display:inline-block;vertical-align:middle;}}}.options{h5{font-size:", ";margin-bottom:", ";margin-top:0;}h6{font-size:", ";font-weight:normal;margin:", " 0;}.options-grid{display:flex;flex-direction:column;@media ", "{flex-direction:row;}}.options-col{flex:1;}ul{display:flex;list-style:none;margin:0;padding:0;li{position:relative;flex:1;margin-right:10px;box-sizing:border-box;max-width:60px;transition:transform .3s ease-in-out;cursor:pointer;@media ", "{margin-right:15px;}& > *{pointer-events:none;}&:hover{transform:scale(1.1);}img{width:100%;}}}}.buy-container{flex:1 0 100%;margin-top:20px;.vat{display:block;margin:10px 10px 0 20px;font-size:", ";text-align:right;}@media ", "{flex:1 0 auto;.vat{display:inline-block;margin:0 10px 0 30px;}}}.call{flex:1 0 100%;margin-top:20px;text-align:center;line-height:1.3em;@media ", "{flex:1 0 auto;}}.buy-block{display:flex;justify-content:space-between;align-items:center;background-color:#FFF;border-radius:38px;padding:12px;.price{font-size:", ";vertical-align:middle;margin-left:10px;margin-right:10px;}@media ", "{display:inline-block;}}"], rem(30), device.laptop, rem(60), rem(30), rem(20), rem(15), device.laptopDown, rem(18), rem(15), rem(15), rem(15), device.tablet, device.laptop, rem(12), device.laptop, device.laptop, rem(28), device.laptop);