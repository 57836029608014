import styled from 'styled-components';
import { rem, device } from '../../utils';
export var Wrapper = styled.div.withConfig({
  componentId: "sc-15izvio-0"
})(["color:#2B2B2B;"]);
export var Container = styled.div.withConfig({
  componentId: "sc-15izvio-1"
})([""]);
export var QuoteContainer = styled.div.withConfig({
  componentId: "sc-15izvio-2"
})(["border-top:1px solid #E2E2E2;margin:20px 0;padding-top:", ";box-sizing:border-box;.relative-container{position:relative;}.cover{width:100%;}@media ", "{margin:20px 7% 30px 7%;.cover{max-width:900px;width:75%;}}"], rem(100), device.laptop);
export var Quote = styled.div.withConfig({
  componentId: "sc-15izvio-3"
})(["margin:20px 0;@media ", "{position:absolute;top:", ";right:0;max-width:50%;z-index:1;margin:0;}@media ", "{top:", ";}p{font-size:", ";line-height:1.3em;@media ", "{font-size:", ";}}.footer{display:flex;align-items:center;.avatar{margin-right:10px;flex:0 0 50px;@media ", "{margin-right:15px;}}& > span{font-size:", ";margin-right:20px;}}"], device.tablet, rem(0), device.laptop, rem(20), rem(15), device.laptop, rem(16), device.tablet, rem(16));