// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import {
  Wrapper,
} from './Brand.styles';

type Props = {
  mainTitle: Boolean,
  noLogo: Boolean,
  small: Boolean,
  className: String,
}

const Brand = (props: Props) => {
  const { logo } = useStaticQuery(
    graphql`
      query Brand {
        logo: file(relativePath: { eq: "common/logo.png" }) {
          childImageSharp {
            fixed(height: 55) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  const title = (<>Champ de Fleurs<sup>©</sup></>);

  return (
    <Wrapper
      className={`${props.small ? 'small' : ''} ${props.className || ''}`}
    >
      {props.noLogo ? null : (
        <Img
          loading="eager"
          className="logo"
          fixed={logo.childImageSharp.fixed}
          alt="Champ de fleur" />
      )}
      {props.mainTitle ? (
        <h1>{title}</h1>
      ): (
        <span>{title}</span>
      )}
    </Wrapper>
  );
};

export default Brand;
