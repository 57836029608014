// @flow

import React from 'react';

type Props = {
  href: String,
  target: String,
  onClick: () => void,
  eventCategory: String,
  eventAction: String,
  eventLabel: String,
}

const OutboundLink = (props: Props) => {

  const aProps = {
    ...props,
  };
  
  delete aProps.eventCategory;
  delete aProps.eventAction;
  delete aProps.eventLabel;

  return (
    <a
      {...aProps}
      onClick={e => {
        if (typeof props.onClick === `function`) {
          props.onClick(e);
        }
        let redirect = true;
        if (
          e.button !== 0 ||
          e.altKey ||
          e.ctrlKey ||
          e.metaKey ||
          e.shiftKey ||
          e.defaultPrevented
        ) {
          redirect = false;
        }
        if (props.target && props.target.toLowerCase() !== `_self`) {
          redirect = false;
        }
        if (window && window.ga) {
          window.ga(`send`, `event`, {
            eventCategory: props.eventCategory || `CDF-LMDD`,
            eventAction: props.eventAction || `click`,
            eventLabel: props.eventLabel || props.href,
            transport: redirect ? `beacon` : ``,
            hitCallback: function() {
              if (redirect) {
                document.location = props.href;
              }
            },
          })
        } else {
          if (redirect) {
            document.location = props.href;
          }
        }

        return false;
      }}
    />
  )
}


export default OutboundLink;
