// @flow

import React from 'react';
import { OutboundLink } from '../utils';

import LogoMauxDeDos from '../images/icons/LogoMauxDeDos.svg';

const eventAction = 'clic-header';

import {
  Wrapper,
  Container,
  Menu,
} from './Header.styles';

const Header = () => (
  <Wrapper>
    <Container
      className="container"
    >
      <OutboundLink
        href="#"
        className="reset-link"
        eventAction={eventAction}
        eventLabel={`${eventAction}-logo`}
      >
        <LogoMauxDeDos height="50px" />
      </OutboundLink>
      <Menu>
        <OutboundLink
          href="https://www.lesmauxdedos.com/pathologies"
          className="menu-item"
          eventAction={eventAction}
          eventLabel={`${eventAction}-pathologies`}
        >
          Pathologies
        </OutboundLink>
        <OutboundLink
          href="https://www.lesmauxdedos.com/boutique"
          className="menu-item"
          eventAction={eventAction}
          eventLabel={`${eventAction}-boutique`}
        >
          Boutique
        </OutboundLink>
        <OutboundLink
          href="https://www.lesmauxdedos.com/blog"
          className="menu-item"
          eventAction={eventAction}
          eventLabel={`${eventAction}-blog`}
        >
          Blog
        </OutboundLink>
        <OutboundLink
          href="https://www.lesmauxdedos.com/boutique/acupression?utm_source=cold&utm_medium=email&utm_campaign=mediafox"
          className="menu-item"
          eventAction={eventAction}
          eventLabel={`${eventAction}-cdf`}
        >
          Champ de Fleurs
        </OutboundLink>
      </Menu>
    </Container>
  </Wrapper>
);

export default Header;
