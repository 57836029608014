// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import HandDrawnPlantIcon from '../../images/icons/HandDrawnPlant.svg';

import {
  Wrapper,
  ImageItem,
  ImageContentItem,
} from './Carousel.styles';

const Carousel = () => {
  const { everywhere, family, men } = useStaticQuery(
    graphql`
      query Carousel {
        everywhere: file(relativePath: { eq: "howto/everywhere.png" }) {
          childImageSharp {
            fluid(maxWidth: 440, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        family: file(relativePath: { eq: "howto/family.png" }) {
          childImageSharp {
            fluid(maxWidth: 440, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        men: file(relativePath: { eq: "howto/men.png" }) {
          childImageSharp {
            fluid(maxWidth: 440, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <div className="container">
      <Wrapper>
        <ImageContentItem
          color="#f0e2cf"
          className="hide-mobile"
        >
          <div className="content">
            <HandDrawnPlantIcon />
          </div>
        </ImageContentItem>
        <ImageItem>
          <Img
            fluid={everywhere.childImageSharp.fluid}
            alt="Partout"
          />
        </ImageItem>
        <ImageContentItem
          color="#d8f3eb"
        >
          <div className="content">
            <h4>Facile à transporter, Champ de Fleurs s'emporte partout&nbsp;!</h4>
          </div>
        </ImageContentItem>
        <ImageContentItem
          color="#dae1ff"
        >
          <div className="content">
            <h4>Accessible à toute la famille</h4>
          </div>
        </ImageContentItem>
        <ImageItem
          className="hide-mobile"
        >
          <Img
            fluid={family.childImageSharp.fluid}
            alt="Famille"
          />
        </ImageItem>
        <ImageItem>
          <Img
            fluid={men.childImageSharp.fluid}
            alt="Homme"
          />
        </ImageItem>
      </Wrapper>
    </div>
  );
};

export default Carousel;
