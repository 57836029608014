// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import OrderButton from '../../common/OrderButton';

const { MAUXDEDOS } = process.env;

import {
  Wrapper,
  Grid,
  Col,
} from './RelatedProducts.styles';

const RelatedProducts = () => {
  const { ceinture, coussin } = useStaticQuery(
    graphql`
      query RelatedProductImages {
        ceinture: file(relativePath: { eq: "products/related/ceinture.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        coussin: file(relativePath: { eq: "products/related/coussin.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  if (!MAUXDEDOS) {
    return null;
  }

  return (
    <Wrapper>
      <h4>Idéal avec :</h4>
      <Grid>
        <Col>
          <div className="cover">
            <Img
              objectFit="contain"
              fluid={coussin.childImageSharp.fluid}
              alt="Coussin champ de Fleurs"
            />
          </div>
          <div className="content">
            <h5>Coussin Champ de Fleurs©</h5>
            <p>
              Idéal pour mieux épouser les formes en courbe du corps comme la 
              région cervicale, les lombaires, le creux des genoux… 
              L’indispensable allié de votre Tapis Champ de Fleurs©
            </p>
            <OrderButton
              outline
              href="https://www.lesmauxdedos.com/boutique/acupression/coussin-champ-de-fleurs"
              text="En savoir plus"
              eventLabel="clic-produit-coussin"
            />
          </div>
        </Col>
        <Col>
          <div className="cover">
            <Img
              fluid={ceinture.childImageSharp.fluid}
              alt="Coussin champ de Fleurs"
            />
          </div>
          <div className="content">
            <h5>Ceinture Champ de Fleurs©</h5>
            <p>
              La ceinture lombaire Champ de Fleurs réinvente 
              l’expérience de réflexologie par acupression. 
              Ce nouvel accessoire de soulagement et de bien être 
              s’enroule autour de votre taille.
            </p>
            <OrderButton
              outline
              href="https://www.lesmauxdedos.com/boutique/acupression/ceinture-champ-de-fleurs"
              text="En savoir plus"
              eventLabel="clic-produit-ceinture"
            />
          </div>
        </Col>
      </Grid>
    </Wrapper>
  );
}

export default RelatedProducts;