// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import CheckedIcon from '../../../images/icons/Checked.svg';

import {
  SelectedMark,
} from './Options.style';

type Props = {
  type: String,
  color: String,
  onSelectColor: () => void,
  onSelectType: () => void,
}

const Options = (props: Props) => {
  const { options } = useStaticQuery(
    graphql`
      query ProductOptions {
        options: allFile(
          filter: {
            absolutePath: { regex:"/products/.*_thumb.png$/" },
          }
        ) {
          edges {
            node {
              name,
              childImageSharp {
                fixed(width: 60) {
                  ...GatsbyImageSharpFixed_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  );

  const images = options.edges.map(e => e.node);

  const getImage = (name = '') => {
    const key = `${name}_`.toUpperCase();
    return images.filter(i => i.name.indexOf(key) > -1).pop();
  }

  const renderType = (image, id) => {
    const selectedType = props.type;
    const selected = selectedType === id;
    return (
      <li
        typeid={id}
        onClick={props.onSelectType}
      >
        <Img
          fixed={image.childImageSharp.fixed}
          alt="Option de produit"
        />
        {selected ? <SelectedMark><CheckedIcon /></SelectedMark> : null}
      </li>
    );
  }

  const renderColor = (image, id) => {
    const selectedType = props.color;
    const selected = selectedType === id;
    return (
      <li
        colorid={id}
        onClick={props.onSelectColor}
      >
        <Img
          fixed={image.childImageSharp.fixed}
          alt="Option de produit"
        />
        {selected ? <SelectedMark><CheckedIcon /></SelectedMark> : null}
      </li>
    );
  }

  return (
    <div className="options-grid">
      <div className="options-col">
        <h6>Tapis</h6>
        <ul>
          {renderType(getImage('TT'), 't')}
          {renderType(getImage('TN'), 'n')}
          {renderType(getImage('TM'), 'm')}
        </ul>
      </div>
      <div className="options-col">
        <h6>Fleurs</h6>
        <ul>
          {renderColor(getImage('FT'), 't')}
          {renderColor(getImage('FR'), 'r')}
          {renderColor(getImage('FV'), 'v')}
          {renderColor(getImage('FO'), 'o')}
        </ul>
      </div>
    </div>
  );
}

export default Options;