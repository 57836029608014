// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import OrderButton from '../common/OrderButton';

import {
  Wrapper,
  Container,
} from './EditoMDD.styles';


const EditoMDD = () => {
  const { cover1, cover2, cover3 } = useStaticQuery(
    graphql`
      query EditoMDD {
        cover1: file(relativePath: { eq: "edito/cover-1.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cover2: file(relativePath: { eq: "edito/cover-2.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        cover3: file(relativePath: { eq: "edito/cover-3.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper
      id="edito"
    >
      <Container
        className="container"
      >
        <div className="inner-text">
          <p className="big center">
            Un soulagement qui s’explique par l’effet combiné de <strong>trois avantages 
            majeurs pour votre dos</strong> : la sécrétion d’endorphines, 
            le soulagement des tensions musculaires et 
            la stimulation de la circulation sanguine.
          </p>
        </div>

        <div className="text-grid">
          <div className="col-img">
            <Img
              fluid={cover1.childImageSharp.fluid}
            />
          </div>
          <div className="col-text">
            <h3>La puissance des endorphines</h3>
            
            <p>
              Si vous souffrez du dos, il est probable que vous fassiez peu d’exercice 
              physique et que vous souffriez donc d’une déficience en endorphines, 
              ce qui empire par conséquent vos douleurs. 
              Hors, l’endorphine est une substance générée uniquement par le corps, 
              qui est plus puissante que les antalgiques à dose égale. 
              Contrairement aux anti-douleurs et anti-inflammatoires artificiels 
              qui sont souvent dangereux (surtout en utilisation régulière), 
              les endorphines sont indispensables au bon fonctionnement 
              du corps et très bénéfiques pour la santé générale. 
              <strong> En stimulant la production d’endorphine</strong>, le tapis d’acupression 
              Champ de Fleurs est donc une solutions efficace pour vos maux de dos.
            </p>
          </div>
        </div>
        <div className="text-grid">
          <div className="col-img">
            <Img
              fluid={cover2.childImageSharp.fluid}
            />
          </div>
          <div className="col-text">
            <h3>Le soulagement des tensions musculaires</h3>
            <p>
              La grande majorité des problèmes de dos trouvent leur origine dans 
              des tensions musculaires, articulaires ou nerveuses qui s’installent et 
              s’accumulent. 
              Ces tensions déséquilibrent les mouvements et postures naturels de la 
              colonne vertébrale, et réduisent la circulation sanguine et l’influx 
              nerveux dans le dos. Au fil du temps, cette situation peut provoquer 
              des hernies discales, la dégénérescence des disques, des pincements 
              discaux et des douleurs sous forme d’inflammations. 
              La <strong>stimulation simultanée de milliers de 
              terminaisons nerveuses</strong> du dos par le Champ de Fleurs détend progressivement 
              toutes les tensions accumulées dans les muscles, les vertèbres et les nerfs. 
              Le Champ de Fleurs est efficace pour détendre les muscles, 
              les vertèbres et les nerfs du dos tout en fournissant à celui-ci 
              l’irrigation sanguine et l’influx nerveux nécessaires à ses capacités de 
              régénération lors d'un mal de dos par exemple.
            </p>
          </div>
        </div>
        <div className="text-grid">
          <div className="col-img">
            <Img
              fluid={cover3.childImageSharp.fluid}
            />
          </div>
          <div className="col-text">
            <h3>La stimulation de la circulation sanguine</h3>
            <p>
              Une déficience de l’afflux sanguin dans les muscles et les articulations du dos 
              peut être la cause de maux de dos. Un afflux de sang adéquat autour des 
              vertèbres est essentiel afin de maintenir les disques vertébraux en bonne 
              santé. 
              Un afflux sanguin abondant est d’autant plus important pour la santé des 
              disques vertébraux que ceux-ci sont avasculaires et dépendent donc d’une 
              bonne irrigation capillaire pour ces échanges . 
              D’autre part, un afflux en eau suffisant autour des disques vertébraux est 
              crucial pour leur santé car ceux-ci se déshydratent légèrement chaque 
              jour et doivent donc se regonfler d’eau pendant la nuit. Un manque de sang et, 
              par conséquent, d’eau autour de vos disques vertébraux entraîne la 
              déshydratation progressive de ceux-ci, ce qui accélère grandement leur 
              détérioration. <strong>Le Champ de Fleurs stimule la circulation dans tout le 
              dos et au-delà.</strong>
            </p>
          </div>
        </div>
        <div className="inner-text no-padding-bottom cdf-headline">
          <p className="big center">
          Le tapis d’acupression Champ de fleurs est donc une solution naturelle pour soulager de nombreuses pathologies parmi lesquelles :
          </p>
        </div>

        <div className="tags">
          <ul>
            <li>
              Arthrose dorsale et lombaire
            </li>
            <li>
              Arthrose du dos
            </li>
            <li>
              Dicsarthrose
            </li>
            <li>
              Discopathie
            </li>
            <li>
              Hernie Discale
            </li>
            <li>
              Mal de dos musculaire
            </li>
            <li>
              Lombalgie
            </li>
            <li>
              Lumbago
            </li>
            <li>
              Mal de dos et inflammation
            </li>
            <li>
              Maladie de Forestier
            </li>
            <li>
              Scoliose
            </li>
            <li>
              Spondylarthrite ankylosante
            </li>
            <li>
              Spondylolisthésis
            </li>
            <li>
              Sténose du canal lombaire
            </li>
          </ul>
          <OrderButton
            showArrow
            text="Découvrir Champ de Fleurs"
            href="https://www.lesmauxdedos.com/boutique/acupression?utm_source=cold&utm_medium=email&utm_campaign=mediafox"
          />
        </div>

        <div className="inner-text">
          <p>
            Le tapis Champ de Fleurs a fait l’objet de tests cliniques officiels au 
            sein de l’Union Européeenne. <strong>Ces tests confirment son efficacité</strong> contre 
            la douleur et les inflammations, l’augmentation de la micro-circulation, 
            l’amélioration du métabolisme cellulaire, l’augmentation de l’oxygénation 
            des tissus, l’augmentation de la sécrétion des facteurs de croissance et de 
            la beta-endorphine, l’amélioration de l’humeur, du sommeil et des 
            capacités physiques et mentales. 
          </p>

        </div>
        
      </Container>
    </Wrapper>
  );
};

export default EditoMDD;
