import styled from 'styled-components';
import { rem, OutboundLink, device } from '../../utils';
export var Wrapper = styled.div.withConfig({
  componentId: "sc-18qfta2-0"
})(["padding:", " 0;@media ", "{padding:", " 0;}color:#2B2B2B;"], rem(40), device.laptop, rem(80));
export var Container = styled.div.withConfig({
  componentId: "sc-18qfta2-1"
})(["text-align:center;"]);
export var Grid = styled.div.withConfig({
  componentId: "sc-18qfta2-2"
})(["display:flex;align-items:flex-start;flex-wrap:wrap;margin:", " 0;"], rem(40));
export var Post = styled(OutboundLink).withConfig({
  componentId: "sc-18qfta2-3"
})(["background-color:#FFF;border-radius:14px;box-shadow:0 10px 17px 0px rgba(240,240,240,0.6);flex:1;margin-right:20px;text-decoration:none;text-align:left;padding:15px 12px;box-sizing:border-box;color:inherit;transition:box-shadow .3s ease-in-out;@media ", "{padding:20px;margin-right:30px;}&:last-child{margin-right:0;}&:hover{box-shadow:0 2px 15px 5px rgba(240,240,240,1);}.header{font-size:", ";font-weight:bold;display:flex;align-items:center;.gatsby-image-wrapper{margin-right:10px;background-color:#d3d3d3;border-radius:50%;width:40px;}}.content{margin:15px 0;max-width:100%;background-color:#d3d3d3;}.footer{display:flex;.left{flex:1;}svg{height:20px;margin-right:10px;@media ", "{height:25px;margin-right:15px;}}.right{flex:0 0 auto;}}"], device.laptop, rem(17), device.laptop);
export var CTA = styled(OutboundLink).withConfig({
  componentId: "sc-18qfta2-4"
})(["text-align:center;color:inherit;text-decoration:none;margin-top:", ";display:block;h4,p{color:inherit;margin:10px 0;}"], rem(60));