// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import { OutboundLink } from '../../utils';
import {
  Wrapper,
  Container,
  TextContainer,
  CoverContainer,
} from './HeroMDD.styles';


const Hero = () => {
  const { cover, coverMobile } = useStaticQuery(
    graphql`
      query HeroMDD {
        cover: file(relativePath: { eq: "hero/hero-cover-mdd.png" }) {
          childImageSharp {
            fluid(maxWidth: 1300, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        coverMobile: file(relativePath: { eq: "hero/hero-cover-mdd-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper>
      <Container
        className="container"
      >
        <TextContainer>
          <div className="inner">
            <div className="header">
              DOSSIER JUILLET 2019<br />
              SOULAGER LES MAUX DE DOS
            </div>
            <h2>
              Des maux de dos soulagés grâce au tapis d’acupression Champ de Fleurs&nbsp;?
            </h2>
            <p>
              Plus de 400.000 personnes ont choisi le tapis d’acupression 
              Champ de Fleurs afin de <strong>soulager leurs douleurs de dos</strong>. 
              Comment expliquer scientifiquement la capacité de ce type 
              de tapis à procurer un tel soulagement&nbsp;? 
              Retour en détails sur l’intérêt de l’acupression pour votre dos 
              et cette solution désormais populaire.
            </p>
            <OutboundLink
              className="cta"
              href="#edito"
              eventAction="click-edito"
              eventLabel="click-edito-more"
            >
              Lire la suite
            </OutboundLink>
          </div>
        </TextContainer>
        <CoverContainer>
          <Img
            fluid={cover.childImageSharp.fluid}
            objectPosition="left center"
            alt="SOULAGER LES MAUX DE DOS"
            className="hide-mobile"
          />
          <Img
            fluid={coverMobile.childImageSharp.fluid}
            objectPosition="top center"
            alt="SOULAGER LES MAUX DE DOS"
            className="mobile-only"
          />
        </CoverContainer>
      </Container>
    </Wrapper>
  );
};

export default Hero;
