// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Title from '../common/Title';
import Brand from '../common/Brand';

import OrderButton from '../common/OrderButton';

import DosIcon from '../../images/icons/Dos.svg';
import SommeilIcon from '../../images/icons/Sommeil.svg';
import CirculationIcon from '../../images/icons/Circulation.svg';
import MauxTeteIcon from '../../images/icons/MauxTete.svg';
import EnergieIcon from '../../images/icons/Energie.svg';
import StressIcon from '../../images/icons/Stress.svg';

import {
  Wrapper,
  Container,
  Grid,
  Item,
  ItemContent,
  CoverWrapper,
  CoverBubble,
} from './KeyPoints.styles';

const datas = [
  {
    icon: DosIcon,
    title: 'Soulage les douleurs et tensions du dos',
    desc: 'Détend les muscles, libère des tensions et réduit les inflammations.'
  },
  {
    icon: SommeilIcon,
    title: 'Améliore la qualité du sommeil',
    desc: 'Apporte un sommeil réparateur.'
  },
  {
    icon: CirculationIcon,
    title: 'Améliore la micro-circulation sanguine',
    desc: 'Oxygène les cellules, libère des douleurs et réduit la cellulite.'
  },
  {
    icon: MauxTeteIcon,
    title: 'Réduit les maux de tête',
    desc: 'Agit sur les migraines et les céphalées.'
  },
  {
    icon: EnergieIcon,
    title: 'Booste l’énergie',
    desc: 'Améliore le niveau d’énergie et le système immunitaire.'
  },
  {
    icon: StressIcon,
    title: 'Diminue le stress',
    desc: 'Apaise le système nerveux, améliore l’humeur.'
  },
];

const renderRow = ({ icon: Icon, title, desc }, id) => (
  <Item
    key={id}
  >
    <ItemContent>
      <Icon />
      <h4>{title}</h4>
      <p>{desc}</p>
    </ItemContent>
  </Item>
);

const Hero = () => {
  const { cover, coverMobile } = useStaticQuery(
    graphql`
      query KeyPoints {
        cover: file(relativePath: { eq: "keypoints/keypoints-cover.png" }) {
          childImageSharp {
            fluid(maxWidth: 1440, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        coverMobile: file(relativePath: { eq: "keypoints/keypoints-cover-mobile.png" }) {
          childImageSharp {
            fluid(maxWidth: 400, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper
      id="bienfaits"
    >
      <Container
        className="container"
      >
        <Title
          text="Améliorez votre état physique, émotionnel et mental"
        />
        <Grid>
          {datas.map(renderRow)}
        </Grid>
        <OrderButton
          showArrow
          href="https://www.lesmauxdedos.com/boutique/acupression?utm_source=cold&utm_medium=email&utm_campaign=mediafox"
        />
        <CoverWrapper>
          <Img
            className="cover mobile-only"
            fluid={coverMobile.childImageSharp.fluid}
            alt="Femme utilisant un tapis Champ de Fleurs"
          />
          <Img
            className="cover hide-mobile"
            fluid={cover.childImageSharp.fluid}
            alt="Femme utilisant un tapis Champ de Fleurs"
          />

          <CoverBubble>
            <Brand small className="hide-mobile" />
            <h3>Technologie brevetée <br /> Testé cliniquement</h3>
          </CoverBubble>
        </CoverWrapper>
      </Container>
    </Wrapper>
  );
};

export default Hero;
