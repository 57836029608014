// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Title from '../common/Title';

import OrderButton from '../common/OrderButton';

import {
  Wrapper,
  Container,
  ContentCol,
  VisualCol,
  Grid,
  CompositionItem,
} from './ManuFacturing.styles';

const ManuFacturing = () => {
  const {
    product,
    certifications,
    coconut,
    linen,
    cotton,
  } = useStaticQuery(
    graphql`
      query ManuFacturing {
        product: file(relativePath: { eq: "manufacturing/product.png" }) {
          childImageSharp {
            fluid(maxWidth: 935, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        certifications: file(relativePath: { eq: "manufacturing/certifications.png" }) {
          childImageSharp {
            fixed(width: 160, toFormat: JPG) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        coconut: file(relativePath: { eq: "manufacturing/coconut.jpg" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        linen: file(relativePath: { eq: "manufacturing/linen.jpg" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        cotton: file(relativePath: { eq: "manufacturing/cotton.jpg" }) {
          childImageSharp {
            fixed(width: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `
  );
  return (
    <Wrapper
      id="matieres"
    >
      <Container
        className="container"
      >
        <Title
          text="Fabriqué avec amour<br />et respect pour la planète"
          className="mobile-only"
        />
        <Grid>
          <ContentCol>
            <Title
              text="Fabriqué avec amour<br />et respect pour la planète"
              left
              className="hide-mobile"
            />

            <p>
              Notre tapis est conçu avec des matières naturelles. 
              Ses fleurs sont en éco-plastique HIPS, un plastique non-toxique et recyclable.
            </p>
            <p>
              Certifié Oeko-tex® et hypoallergénique, Champ de Fleurs©
              garantit une utilisation quotidienne sans risque, même pour les peaux sensibles. 
            </p>

            <OrderButton
              showArrow
              href="https://www.lesmauxdedos.com/boutique/acupression?utm_source=cold&utm_medium=email&utm_campaign=mediafox"
            />
          </ContentCol>
          <VisualCol>
            <Img
              className="product"
              fluid={product.childImageSharp.fluid}
              alt="tapis Champ de Fleurs"
            />
            <div className="certifications">
              <Img
                className="certifications"
                fixed={certifications.childImageSharp.fixed}
                alt="Fabriqué dans l'Union Européenne"
              />
            </div>
          </VisualCol>
        </Grid>
        <p className="composition-header">Parmi les composants clés de Champ de Fleurs© :</p>
        <Grid>
          <CompositionItem>
            <Img
              fixed={coconut.childImageSharp.fixed}
              alt="fibres de coco"
            />
            <div className="content">
              <h4>Fibres de coco</h4>
              <p>Pour un matelas résistant</p>
            </div>
          </CompositionItem>
          <CompositionItem>
            <Img
              fixed={linen.childImageSharp.fixed}
              alt="Fibres de lin"
            />
            <div className="content">
              <h4>Fibres de lin</h4>
              <p>Pour une housse hygiénique</p>
            </div>
          </CompositionItem>
          <CompositionItem>
            <Img
              fixed={cotton.childImageSharp.fixed}
              alt="Fleurs de coton"
            />
            <div className="content">
              <h4>Fleurs de coton</h4>
              <p>Pour une doublure saine et durable</p>
            </div>
          </CompositionItem>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default ManuFacturing;
