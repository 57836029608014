// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Youtube from 'react-youtube';
import Img from 'gatsby-image';

import Title from '../common/Title';

import OrderButton from '../common/OrderButton';

import {
  Wrapper,
  Container,
} from './HowTo.styles';

type Props = {
  cover: String,
}

type State = {
  isPlaying: Boolean,
}

class VideoPlayer extends React.PureComponent<Props, State> {
  state = {
    isPlaying: false,
  }

  play = () => {
    console.log('play');
    this.setState({
      isPlaying: true,
    });
  }

  onEnd = () => {
    console.log('stop');
    this.setState({
      isPlaying: false,
    });
  }

  onReady = (event) => {
    event.target.playVideo();
  }

  render = () => {
    const { isPlaying } = this.state;
    const { cover } = this.props;

    return (
      <div
        className={`video-wrapper ${isPlaying ? 'playing' : ''}`}
        onClick={this.play}
      >
        {isPlaying ? (
          <Youtube
            containerClassName="video-container"
            videoId="0ALIel3BcdY"
            opts={{
              width: '100%',
              height: '100%',
              playerVars: {
                // autoplay: 1,
                rel: 0,
                modestbranding: 1,
              }
            }}
            onEnd={this.onEnd}
            onReady={this.onReady}
            allowFullscreen
          />
        ) : null}
        <Img
          className="cover-image"
          fluid={cover.childImageSharp.fluid}
          alt="Video champ de fleurs"
        />
      </div>
    );
  }
}

const HowTo = () => {
  const { videoCover } = useStaticQuery(
    graphql`
      query HowTo {
        videoCover: file(relativePath: { eq: "howto/video.png" }) {
          childImageSharp {
            fluid(maxWidth: 930, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <Wrapper>
      <Container
        className="container"
      >
        <Title
          text="Comment ça marche ?"
        />

        <div className="grid">
          <div className="content">
            <h4>Choisissez la durée de chaque séance</h4>
            <p>
              Nous vous recommandons des séances allant de 15 à 45 minutes, 
              mais vous pouvez poursuivre sasn danger autant que vous le souhaitez. 
              Néanmoins, la plus part des effets bénéfiques : 
              soulagement, détente, stimulation et régulation, 
              sont obtenus en moins de 45 minutes.
            </p>
            <h4>Renouvellez les séances à votre rythme</h4>
            <p>
              Vous pouvez utiliser Champ de Fleurs pour soulager 
              une douleur ponctuelle ou plus régulièrement 
              pour améliorer la santé du dos et conserver 
              les muscles et le système nerveux bien détendus. 
              Pour optimiser au mieux son effet, 
              nous vous recommandons une séance par jour.
            </p>
            <OrderButton
              className="cta"
              showArrow
              href="https://www.lesmauxdedos.com/boutique/acupression?utm_source=cold&utm_medium=email&utm_campaign=mediafox"
            />
          </div>
          <div className="video">
            <VideoPlayer
              cover={videoCover}
            />
          </div>
        </div>
      </Container>
    </Wrapper>
  );
};

export default HowTo;
