/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from "react";
import Helmet from 'react-helmet';
import GothamMediaWoff2 from '../fonts/gotham/gotham-medium.woff2';
import GothamBookWoff2 from '../fonts/gotham/gotham-book.woff2';
import Footer from "./Footer";
import GlobalStyles from '../shared/globalStyles';
import { Main } from './Layout.styles';
var Header;
var MAUXDEDOS = process.env.MAUXDEDOS;

if (MAUXDEDOS) {
  Header = require('./HeaderMDD').default;
} else {
  Header = require('./HeaderCDF').default;
}

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    speed: 400,
    offset: function offset() {
      return document.documentElement.clientWidth >= 768 ? 95 : 0;
    }
  });
}

var Layout = function Layout(_ref) {
  var children = _ref.children;
  return React.createElement(React.Fragment, null, React.createElement(Helmet, null, React.createElement("link", {
    rel: "preload",
    as: "font",
    href: GothamMediaWoff2,
    type: "font/woff2",
    crossOrigin: "anonymous"
  }), React.createElement("link", {
    rel: "preload",
    as: "font",
    href: GothamBookWoff2,
    type: "font/woff2",
    crossOrigin: "anonymous"
  })), React.createElement(GlobalStyles, null), React.createElement(Header, null), React.createElement(Main, null, children), React.createElement(Footer, null));
};

export default Layout;