import styled from 'styled-components';
import { rem, device } from '../../utils';
export var Wrapper = styled.div.withConfig({
  componentId: "h9s08d-0"
})(["display:flex;flex-wrap:wrap;margin:0 -2%;text-align:center;"]);
export var ImageItem = styled.div.withConfig({
  componentId: "h9s08d-1"
})(["flex:0 0 50%;padding:2%;box-sizing:border-box;height:auto;@media ", "{flex:0 0 33%;}img{width:100%;}"], device.tablet);
export var ImageContentItem = styled(ImageItem).withConfig({
  componentId: "h9s08d-2"
})([".content{display:flex;justify-content:center;align-items:center;height:100%;background-color:", ";h4{max-width:80%;margin:20px auto;font-size:", ";line-height:1.3em;}@media ", "{h4{max-width:60%;font-size:", ";}}}"], function (p) {
  return p.color;
}, rem(16), device.tablet, rem(20));