// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const RenderProductImage = (props) => {
  const id = `${props.type}${props.color}`.toUpperCase();
  const { images } = useStaticQuery(
    graphql`
      query ProductImages {
        images: allFile(
          filter: {
            absolutePath: { regex:"/products/.*jpg$/" },
          }
        ) {
          edges {
            node {
              name,
              childImageSharp {
                fluid(maxWidth: 380) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  );

  return images.edges.map(e => e.node).map((node) => (
    <Img
      key={node.name}
      className={`product-image ${node.name === id ? 'active' : 'hide'}`}
      fluid={node.childImageSharp.fluid}
      alt={`Champ de fleur ${node.name}`}
    />
  ));
}

export default RenderProductImage;