import styled from 'styled-components';
import { rem, device } from '../../utils';
export var Wrapper = styled.div.withConfig({
  componentId: "ao9462-0"
})(["margin:", " 0 ", ";position:relative;"], rem(80), rem(30));
export var Container = styled.div.withConfig({
  componentId: "ao9462-1"
})([".composition-header{margin:40px 0 30px;}"]);
export var Grid = styled.div.withConfig({
  componentId: "ao9462-2"
})(["position:relative;display:flex;align-items:center;flex-direction:column-reverse;@media ", "{flex-direction:row;}"], device.tablet);
export var ContentCol = styled.div.withConfig({
  componentId: "ao9462-3"
})(["flex:0 0 45%;padding-right:50px;box-sizing:border-box;@media ", "{flex:0 0 35%;}p{line-height:1.3em;font-size:", ";margin:", " 0;}a{margin-top:", ";@media ", "{margin-top:", ";}}"], device.laptop, rem(17), rem(30), rem(20), device.tablet, rem(50));
export var VisualCol = styled.div.withConfig({
  componentId: "ao9462-4"
})(["flex:1;box-sizing:border-box;min-height:100px;width:100%;position:relative;margin-bottom:20px;@media ", "{flex:1 1 auto;width:auto;}.product{max-width:100%;height:100%;}.certifications{position:absolute;bottom:0;right:0;width:80px!important;height:40px!important;@media ", "{right:30px;width:160px!important;height:81px!important;}}"], device.tablet, device.laptop);
export var CompositionItem = styled.div.withConfig({
  componentId: "ao9462-5"
})(["flex:1;display:flex;justify-content:flex-start;align-items:center;margin-bottom:20px;width:100%;@media ", "{flex:0 0 33%;width:auto;}.gatsby-image-wrapper{background-color:#d3d3d3;border-radius:50%;flex:0 0 60px;height:60px!important;@media ", "{flex:0 0 100px;width:100px;height:100px!important;}}.content{display:inline-block;margin:0 20px;flex:1 1 auto;h4{margin:0;font-size:", ";}p{margin:", " 0 0 0;font-size:", ";}}"], device.laptop, device.laptop, rem(17), rem(10), rem(16));