import styled from 'styled-components';
import { rem, device } from '../../utils';
export var Wrapper = styled.div.withConfig({
  componentId: "ao7hij-0"
})(["position:relative;background:#F7F6F9;a.cta{color:#02B179;border:1px solid #02B179;padding:", " ", ";text-decoration:none;border-radius:26px;font-size:", ";background-color:rgba(255,255,255,0.5);overflow:hidden;transition:background-color .3s;@media ", "{background-color:rgba(255,255,255,0);}&:hover{background-color:rgba(255,255,255,1);}}"], rem(10), rem(20), rem(17), device.tablet);
export var Container = styled.div.withConfig({
  componentId: "ao7hij-1"
})(["display:flex;flex-direction:column;@media ", "{flex-direction:row;}"], device.tablet);
export var CoverContainer = styled.div.withConfig({
  componentId: "ao7hij-2"
})(["flex:1;margin-right:-30px;margin-left:-30px;margin-top:220px;@media screen and (min-width:1440px){margin-right:calc(720px - 50vw + 8px);}@media ", "{margin-left:0;margin-top:0;}max-height:630px;.gatsby-image-wrapper{max-height:100%;}"], device.tablet);
export var TextContainer = styled.div.withConfig({
  componentId: "ao7hij-3"
})(["flex:1;position:relative;@media ", "{flex:0 0 30%;}.inner{position:absolute;top:0;left:0;right:0;z-index:1;margin:", " 0 ", ";@media ", "{right:-20%;right:-20vw;}@media ", "{margin:", " 0;right:-20%;right:-10vw;}@media ", "{margin:", " 0;}}.header{color:#6F6F6F;line-height:1.3em;font-size:", ";@media ", "{font-size:", ";}}h2{font-size:", ";@media ", "{margin:20px 0;font-size:", ";}}p{font-size:", ";line-height:1.3em;margin-bottom:", ";@media ", "{font-size:", ";}}"], device.tablet, rem(40), rem(20), device.tablet, device.laptop, rem(70), device.laptopM, rem(100), rem(13), device.laptopM, rem(16), rem(28), device.laptopM, rem(40), rem(16), rem(30), device.laptopM, rem(17));