import styled from 'styled-components';
import { rem, device } from '../utils';
import { Wrapper as OrderButtonWrapper } from './common/OrderButton.styles';
export var Wrapper = styled.header.withConfig({
  componentId: "doy96r-0"
})(["padding:20px 0;@media ", "{position:fixed;top:0;right:0;left:0;z-index:1000;background:#FFF;}.reset-link{color:inherit;text-decoration:none;}"], device.tablet);
export var Container = styled.div.withConfig({
  componentId: "doy96r-1"
})(["display:flex;flex-direction:row;align-items:center;.cart-right{margin-left:auto;}"]);
export var Menu = styled.div.withConfig({
  componentId: "doy96r-2"
})(["flex:1;text-align:right;@media ", "{display:none;}.menu-item{color:#6F6F6F;font-size:", ";padding:5px ", ";margin:0 ", ";text-decoration:none;}", "{margin-left:30px;}"], device.laptopDown, rem(17), rem(15), rem(15), OrderButtonWrapper);