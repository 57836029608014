// @flow

import React from 'react';

import Title from '../common/Title';

import OrderButton from '../common/OrderButton';

import {
  Wrapper,
  Container,
} from './Acupressure.styles';

const Acupressure = () => (
  <Wrapper
    id="acupression"
  >
    <Container
      className="container"
    >
      <Title
        text="Allongez-vous et soulagez <br />vos tensions"
      />

      <div className="grid first-row">
        <div className="visuals highlight" />
        <div className="content">
          <h4>5000 points du dos stimulés</h4>
          <p>
            Avec ses 221 stimulateurs de 25 pétales en forme de pointes, 
            le tapis Champ de Fleurs exerce une pression qui 
            <strong> permet aux muscles de se relâcher.</strong>
          </p>
        </div>
      </div>
      <div className="grid">
        <div className="visuals" />
        <div className="content">
          <h4>Une médecine douce et ancestrale</h4>
          <p>
            Issue de la médecine traditionnelle chinoise,
            <strong> l’acupression</strong> procure une détente profonde des muscles et du système nerveux. 
            Elle est reconnue dans le monde entier pour ses multiples vertus.
          </p>
        </div>
      </div>
      <div className="grid">
        <div className="visuals" />
        <div className="content">
          <h4>Un antidouleur totalement naturel</h4>
          <p>
            La pression exercée par les pétales sur les points d’acupression 
            libère des <strong>endorphines</strong>. Bonne nouvelle, c’est l’hormone du bonheur.
          </p>
        </div>
      </div>
      <div className="grid cta">
        <div className="visuals hide-images" />
        <div className="content">
          <OrderButton
            showArrow
            href="https://www.lesmauxdedos.com/boutique/acupression?utm_source=cold&utm_medium=email&utm_campaign=mediafox"
          />
        </div>
      </div>
    </Container>
  </Wrapper>
);

export default Acupressure;
