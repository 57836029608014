import styled from 'styled-components';
import { rem, device } from '../../../utils';
export var Wrapper = styled.div.withConfig({
  componentId: "sc-14b66e6-0"
})(["padding:0px;@media ", "{padding:0px 30px 30px;}h4{font-size:", ";margin-top:0;padding-left:20px;}"], device.tablet, rem(20));
export var Grid = styled.div.withConfig({
  componentId: "sc-14b66e6-1"
})(["display:flex;flex-direction:column;@media ", "{flex-direction:row;}"], device.laptop);
export var Col = styled.div.withConfig({
  componentId: "sc-14b66e6-2"
})(["flex:1;display:flex;align-items:center;margin-bottom:30px;flex-direction:column;@media ", "{flex-direction:row;}@media ", "{margin-bottom:0px;}.cover{flex:1;padding:10px;width:100%;box-sizing:border-box;@media ", "{flex:0 0 250px;width:250px;}.gatsby-image-wrapper{width:100%;max-width:230px;margin:0 auto;}}.content{flex:1;box-sizing:border-box;padding:10px 20px;align-self:flex-start;h5{font-size:", ";margin:10px 0;}p{font-size:", ";line-height:1.3em;}}"], device.tablet, device.laptop, device.laptop, rem(23), rem(15));