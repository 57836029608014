function _templateObject() {
  var data = _taggedTemplateLiteralLoose(["\n  body {\n    font-family: 'Gotham', Helvetica, Arial, sans-serif;\n    margin: 0;\n  }\n\n  html, body {\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    -ms-text-size-adjust: 100%;\n    -webkit-text-size-adjust: 100%;\n    font-size: 12px;\n\n    @media ", " {\n      font-size: 14px;\n    }\n\n    @media ", " {\n      font-size: 15px;\n    }\n\n    @media ", " {\n      font-size: 16px;\n    }\n\n    color: #2B2B2B;\n  }\n\n  * {\n    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;\n  }\n\n  .container {\n    max-width: 1440px;\n    margin: 0 30px;\n\n    @media screen and (min-width: 1500px) {\n      margin: 0 auto;\n    }\n  }\n\n  .mobile-only {\n    @media ", " {\n      display: none!important;\n    }\n  }\n\n  .hide-mobile {\n    @media ", " {\n      display: none!important;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteralLoose(strings, raw) { if (!raw) { raw = strings.slice(0); } strings.raw = raw; return strings; }

import { createGlobalStyle } from 'styled-components';
import 'normalize.css';
import { device } from '../utils';
export default createGlobalStyle(_templateObject(), device.tablet, device.laptop, device.laptopL, device.tablet, device.tabletDown);