// @flow

import React from 'react';

import ArrowIcon from '../../images/icons/Arrow.svg';

import {
  Wrapper,
} from './OrderButton.styles';

type Props = {
  outline: Boolean,
  text: String,
  showArrow: Boolean,
  big: Boolean,
  href: ?String,
  className: ?String,
  showIcon: ?any,
  target: ?String,
  eventLabel: ?String,
}

const OrderButton = (props: Props) => (
  <Wrapper
    className={`
      ${props.outline ? 'outline' : ''} 
      ${props.big ? 'big' : ''} 
      ${props.className || ''} 
      ${props.showIcon ? 'icon-only' : ''} 
    `.trim()}
    href={props.href || '#commander'}
    target={props.target}
    eventAction="clic-produit"
    eventLabel={props.eventLabel || (props.href && props.href.indexOf('#') === 0 ? 'clic-produit-scroll' : 'clic-produit-commander')}
  >
    {props.showIcon ?
      <props.showIcon /> :
      (
        <>
        {props.showArrow ? <ArrowIcon /> : null}
        <span dangerouslySetInnerHTML={{ __html: props.text || 'Commander maintenant'}} />
        </>
      )}
  </Wrapper>
);

export default OrderButton;
