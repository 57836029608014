// @flow

import React from 'react';
import { OutboundLink } from '../utils';
import Brand from './common/Brand';
import OrderButton from './common/OrderButton';

import BuyIcon from '../images/icons/Buy.svg';

import {
  Wrapper,
  Container,
  Menu,
} from './Header.styles';

const Header = () => (
  <Wrapper>
    <Container
      className="container"
    >
      <OutboundLink
        href="#"
        className="reset-link"
        eventCategory="Navigation"
        eventAction="Click Logo"
      >
        <Brand />
      </OutboundLink>
      <Menu>
        <OutboundLink
          href="#bienfaits"
          className="menu-item"
          eventCategory="Navigation"
          eventAction="Section Bienfaits"
        >
          Les bienfaits
        </OutboundLink>
        <OutboundLink
          href="#acupression"
          className="menu-item"
          eventCategory="Navigation"
          eventAction="Section Acupression"
        >
          {"L'acupression"}
        </OutboundLink>
        <OutboundLink
          href="#matieres"
          className="menu-item"
          eventCategory="Navigation"
          eventAction="Section Matières"
        >
          Les matières
        </OutboundLink>
        <OrderButton
          outline
          text="Commander votre tapis"
          href="#commander"
        />
      </Menu>
      <OrderButton
        className="mobile-only cart-right"
        showIcon={BuyIcon}
        href="#commander"
      />
    </Container>
  </Wrapper>
);

export default Header;
