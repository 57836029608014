import React from 'react';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';
import KeyPointsSection from '../../components/sections/KeyPoints';
import AcupressureSection from '../../components/sections/Acupressure';
import CarouselSection from '../../components/sections/Carousel';
import HowToSection from '../../components/sections/HowTo';
import ManuFacturingSection from '../../components/sections/ManuFacturing';
import BuySection from '../../components/sections/Buy';
import SocialSection from '../../components/sections/Social';
import VerbatimSection from '../../components/sections/Verbatim';
var MAUXDEDOS = process.env.MAUXDEDOS;
var HeroSection;

if (MAUXDEDOS) {
  HeroSection = require('../../components/sections/HeroMDD').default;
} else {
  HeroSection = require('../../components/sections/HeroCDF').default;
}

var EditorSection;

if (MAUXDEDOS) {
  EditorSection = require('../../components/sections/EditoMDD').default;
}

var IndexPage = function IndexPage() {
  return React.createElement(Layout, null, React.createElement(SEO, {
    title: MAUXDEDOS ? "LesMauxDeDos: Tout savoir sur le mal de dos et pathologies li\xE9es" : "Champ de Fleurs"
  }), React.createElement(HeroSection, null), !!EditorSection && React.createElement(EditorSection, null), MAUXDEDOS ? React.createElement(HowToSection, null) : null, React.createElement(KeyPointsSection, null), React.createElement(AcupressureSection, null), !MAUXDEDOS ? React.createElement(HowToSection, null) : null, React.createElement(CarouselSection, null), React.createElement(ManuFacturingSection, null), !MAUXDEDOS ? React.createElement(BuySection, null) : null, React.createElement(SocialSection, null), React.createElement(VerbatimSection, null));
};

export default IndexPage;