import styled from 'styled-components';
import { rem, device } from '../../utils';
import { Wrapper as BrandWrapper } from '../common/Brand.styles';
export var Wrapper = styled.div.withConfig({
  componentId: "sc-2z61x5-0"
})(["position:relative;"]);
export var Container = styled.div.withConfig({
  componentId: "sc-2z61x5-1"
})(["display:flex;flex-direction:column;.trustpilot{margin-left:-5px;}@media ", "{flex-direction:row;}"], device.laptop);
export var CoverContainer = styled.div.withConfig({
  componentId: "sc-2z61x5-2"
})(["flex:1;margin-right:-30px;@media screen and (min-width:1440px){margin-right:calc(720px - 50vw + 8px);}"]);
export var TextContainer = styled.div.withConfig({
  componentId: "sc-2z61x5-3"
})(["flex:1;margin:", " 0 ", ";@media ", "{margin:", " 0;}h2{font-weight:normal;margin:10px 0;&,", " h1,span{font-size:", ";@media ", "{font-size:", ";}}}h3{font-size:", ";@media ", "{font-size:", ";}}.trustpilot{width:220px;@media ", "{width:250px;}@media ", "{width:270px;}}@media ", "{flex:0 0 40%;}"], rem(60), rem(20), device.laptopM, rem(100), BrandWrapper, rem(20), device.laptopM, rem(27), rem(40), device.laptopM, rem(50), device.tablet, device.laptop, device.laptop);
export var PressContainer = styled.div.withConfig({
  componentId: "sc-2z61x5-4"
})(["width:100%;margin-bottom:", ";h4{font-size:", ";font-weight:normal;color:#6F6F6F;margin:30px 0 15px;}ul{padding:0;list-style:none;display:flex;align-items:center;flex-wrap:wrap;margin:0 -20px;@media ", "{margin:0;}li{display:inline-block;flex:0 0 33%;&:last-child{display:none;}padding:10px 20px;box-sizing:border-box;@media ", "{flex:1;padding:10px 30px;&:last-child{display:inline-block;}&:first-child,&:last-child{padding-left:0;padding-right:0;display:inline-block;}&:first-child{.gatsby-image-wrapper{margin-left:0;}}&:last-child{.gatsby-image-wrapper{margin-right:0;}}}.gatsby-image-wrapper{max-height:35px;max-width:130px;margin:0 auto;}}}"], rem(50), rem(18), device.tablet, device.tablet);