// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Title from '../common/Title';

import InstaLove from '../../images/icons/InstaLove.svg';
import InstaComment from '../../images/icons/InstaComment.svg';
import InstaShare from '../../images/icons/InstaShare.svg';
import InstaBookmark from '../../images/icons/InstaBookmark.svg';
import Instagram from '../../images/icons/Instagram.svg';

import {
  Wrapper,
  Container,
  Grid,
  Post,
  CTA,
} from './Social.styles';

const Social = () => {
  const { firstAuthor, first, secondAuthor, second, thirdAuthor, third } = useStaticQuery(
    graphql`
      query InstagramPosts {
        first: file(relativePath: { eq: "social/1.png" }) {
          childImageSharp {
            fluid(maxWidth: 420, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        second: file(relativePath: { eq: "social/2.png" }) {
          childImageSharp {
            fluid(maxWidth: 420, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        third: file(relativePath: { eq: "social/3.png" }) {
          childImageSharp {
            fluid(maxWidth: 420, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        firstAuthor: file(relativePath: { eq: "social/author-1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 45) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        secondAuthor: file(relativePath: { eq: "social/author-2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 45) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        thirdAuthor: file(relativePath: { eq: "social/author-3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 45) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  const renderPost = (id, link, username, avatar, image) => (
    <Post
      key={id}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      eventCategory="External"
      eventAction="Instagram"
    >
      <div className="header">
        <Img
          fluid={avatar}
          alt="Instagram"
        />
        {username}
      </div>
      <Img
        className="content"
        fluid={image}
        alt="Instagram"
      />
      <div className="footer">
        <div className="left">
          <InstaLove />
          <InstaComment />
          <InstaShare />
        </div>
        <div className="right">
          <InstaBookmark />
        </div>
      </div>
    </Post>
  );

  return (
    <Wrapper
      className="hide-mobile"
    >
      <Container
        className="container"
      >
        <Title
          text="Rejoignez la communauté Champ de Fleurs© !"
        />
        <Grid>
          {renderPost(
            1,
            "https://www.instagram.com/p/BlvsWtWFMo3/",
            "zoebassetto",
            firstAuthor.childImageSharp.fluid,
            first.childImageSharp.fluid,
          )}
          {renderPost(
            2,
            "https://www.instagram.com/p/BkfxymWgFVt/",
            "la.mouette",
            secondAuthor.childImageSharp.fluid,
            second.childImageSharp.fluid,
          )}
          {renderPost(
            3,
            "https://www.instagram.com/p/Bl5ScNFF0gu/",
            "julendqr",
            thirdAuthor.childImageSharp.fluid,
            third.childImageSharp.fluid,
          )}
        </Grid>
        <CTA
          href="https://www.instagram.com/champdefleurs_officiel/"
          target="_blank"
          rel="noopener noreferrer"
          eventAction="clic-reseaux-sociaux"
          eventLabel="clic-LS-RS-Instagram"
        >
          <Instagram />
          <h4>Suivez-nous sur Instagram</h4>
          <p>@champdefleurs_officiel</p>
        </CTA>
      </Container>
    </Wrapper>
  );
};

export default Social;
