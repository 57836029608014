// @flow

import React, { PureComponent } from 'react';

import Title from '../../common/Title';

import OrderButton from '../../common/OrderButton';
import ProductImage from './ProductImage';
import RelatedProducts from './RelatedProducts';
import ProductOption from './Options';

import GuaranteeIcon from '../../../images/icons/Guarantee.svg';
import SecureIcon from '../../../images/icons/Secure.svg';
import SAVIcon from '../../../images/icons/SAV.svg';

import NaturalIcon from '../../../images/icons/Natural.svg';
import CertifiedIcon from '../../../images/icons/Certified.svg';
import UEIcon from '../../../images/icons/EU.svg';
import RulerIcon from '../../../images/icons/Ruler.svg';

import ProductShape from '../../../images/products/loader.svg';

import {
  Wrapper,
  Gradient,
  Container,
  CartWrapper,
  CartOptionsCol,
  CartProductCol,
} from './Buy.styles';

const { MAUXDEDOS } = process.env;

type State = {
  selectedType: String,
  selectedColor: String,
}

class Buy extends PureComponent<void, State> {
  state = {
    selectedType: 't',
    selectedColor: 't',
  }

  selectType = (e) => {
    const node = e.target;

    if (!node) {
      return;
    }

    const selectedType = node.getAttribute('typeid');
    this.setState({
      selectedType,
    });
  }

  selectColor = (e) => {
    const node = e.target;

    if (!node) {
      return;
    }

    const selectedColor = node.getAttribute('colorid');
    this.setState({
      selectedColor,
    });
  }

  renderProductImage = () => {
    const { selectedType, selectedColor } = this.state;

    return (
      <ProductImage
        type={selectedType}
        color={selectedColor}
      />
    );
  }

  getCartLink = () => {
    const { selectedType, selectedColor } = this.state;
    const id = `${selectedType}${selectedColor}`.toUpperCase();

    return `http://www.champdefleurs.fr/ac.php?sku=CDF${id}&site=${MAUXDEDOS ? 'lmdd' : 'cdf'}&auto=1`;
  }


  render = () => {
    const { selectedType, selectedColor } = this.state;

    return (
      <Wrapper
        id="commander"
      >
        <Gradient>
          <Container
            className="container"
          >
            <Title
              text="Commandez votre tapis Champ de Fleurs<sup>©</sup>"
            />
            <CartWrapper>
              <CartProductCol>
                <div className="product-image-container">
                  <div className="loader">
                    <img src={ProductShape} className="product-image" alt="Product shape" />
                  </div>
                  {this.renderProductImage()}
                </div>
                <ul
                  className="reinsurance"
                >
                  <li>
                    <GuaranteeIcon />
                    <h4>Garantie 30 jours</h4>
                    <p>Satisfait ou remboursé</p>
                  </li>
                  <li>
                    <SecureIcon />
                    <h4>Paiement sécurisé</h4>
                    <p>Déjà 400.000 clients</p>
                  </li>
                  <li>
                    <SAVIcon />
                    <h4>Service client</h4>
                    <p>basé en France</p>
                  </li>
                </ul>
              </CartProductCol>
              <CartOptionsCol>
                <div
                  className="description hide-mobile"
                >
                  <h4>Tapis Champ de Fleurs<sup>©</sup></h4>
                  <p>
                    Maux de dos ? Douleurs lombaires ? 
                    Le tapis Champ de Fleurs est un matelas recouvert de 221 stimulateurs brevetés. 
                    Ses 25 pétales qui se terminent en pointe, vous apportent bien-être et détente. 
                    Une fois allongé, 5000 points du dos sont alors stimulés en simultané. 
                    Le soulagement est obtenu par une importante émission d’endorphines et 
                    le déblocage des points de tension.
                  </p>
                  <ul
                    className="keypoints"
                  >
                    <li>
                      <span className="icon"><NaturalIcon /></span>
                      <span>Matières naturelles</span>
                    </li>
                    <li>
                      <span className="icon"><CertifiedIcon /></span>
                      <span>Breveté, testé cliniquement</span>
                    </li>
                    <li>
                      <span className="icon ue"><UEIcon /></span>
                      <span>Union Européenne</span>
                    </li>
                    <li>
                      <span className="icon"><RulerIcon /></span>
                      <span>73 cm x 45 cm</span>
                    </li>
                  </ul>
                </div>
                <div className="options">
                  <h5>Choisissez votre couleur</h5>
                  <ProductOption
                    type={selectedType}
                    color={selectedColor}
                    onSelectColor={this.selectColor}
                    onSelectType={this.selectType}
                  />
                </div>
                <div className="buy-container">
                  <div className="buy-block">
                    <span className="price">99€</span>
                    <OrderButton
                      showArrow
                      big
                      text={"Commander <span class=\"hide-mobile\">maintenant</span>"}
                      href={this.getCartLink()}
                    />
                  </div>
                  <div className="vat">Tarif TTC</div>
                </div>
                <div className="call">
                  <strong>Une question ?</strong> <br className="mobile-only" />Contactez-nous au <strong>01 80 96 38 70</strong><br />
                  (appel non surtaxé depuis la France)
                </div>
              </CartOptionsCol>
            </CartWrapper>
          </Container>
        </Gradient>
        <Container
          className="container"
        >
          <RelatedProducts />
        </Container>
      </Wrapper>
    );
  }
}

export default Buy;
