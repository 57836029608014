// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import Brand from '../common/Brand';
import OrderButton from '../common/OrderButton';

import {
  Wrapper,
  Container,
  TextContainer,
  CoverContainer,
  PressContainer,
} from './HeroCDF.styles';


const Hero = () => {
  const { cover, trustpilot, press } = useStaticQuery(
    graphql`
      query HeroCDF {
        cover: file(relativePath: { eq: "hero/hero-cover.png" }) {
          childImageSharp {
            fluid(maxWidth: 1050, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        trustpilot: file(relativePath: { eq: "hero/trustpilot.png" }) {
          childImageSharp {
            fluid(maxHeight: 35, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        press: allFile(
          filter: {
            absolutePath: { regex:"/hero/press/.*jpg$/" },
          }
        ) {
          edges {
            node {
              name,
              childImageSharp {
                fluid(maxWidth: 150) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    `
  );

  const pressImages = press.edges.map(e => e.node);

  return (
    <Wrapper>
      <Container
        className="container"
      >
        <TextContainer>
          <h2>Tapis d’acupression <Brand noLogo /></h2>
          <Img
            loading="eager"
            className="trustpilot"
            fluid={trustpilot.childImageSharp.fluid}
            alt="TrustPilot"
          />
          <h3>
            La solution pour les maux de dos adoptée par 400.000 français&nbsp;!
          </h3>
          <OrderButton
            showArrow
            text="Choisissez le vôtre"
            href="#commander"
          />
        </TextContainer>
        <CoverContainer>
          <Img
            fluid={cover.childImageSharp.fluid}
            objectPosition="left bottom"
            alt="Champ de fleurs"
          />
        </CoverContainer>
      </Container>
      <Container
        className="container"
      >
        <PressContainer>
          <h4>Ils en parlent</h4>
          <ul>
            {pressImages.map((image, index) => (
              <li
                key={index}
              >
                <Img
                  objectFit="contain"
                  objectPosition={index === 0 ? 'left center' : (index === pressImages.length - 1 ? 'right center' : 'center')}
                  fluid={image.childImageSharp.fluid}
                  alt={image.name}
                />
              </li>
            ))}
          </ul>
        </PressContainer>
      </Container>
    </Wrapper>
  );
};

export default Hero;
