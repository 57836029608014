// @flow

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Title from '../common/Title';
import OrderButton from '../../components/common/OrderButton';

import {
  Wrapper,
  Container,
  Quote,
  QuoteContainer,
} from './Verbatim.styles';

const Verbatim = () => {
  const { cover, author } = useStaticQuery(
    graphql`
      query Verbatim {
        cover: file(relativePath: { eq: "verbatim/cover.png" }) {
          childImageSharp {
            fluid(maxWidth: 900, toFormat: JPG) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        },
        author: file(relativePath: { eq: "verbatim/author.png" }) {
          childImageSharp {
            fixed(width: 50, toFormat: JPG) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `
  );


  return (
    <Wrapper>
      <Container
        className="container"
      >
        <QuoteContainer>
          <Title
            text="Testé et approuvé !"
          />
          <div className="relative-container">
            <Img
              className="cover"
              fluid={cover.childImageSharp.fluid}
              alt="Champ de fleurs"
            />
            <Quote>
              <p>
                « Ca fait maintenant près d’un an et demi que mes patients suivent pour la plupart le protocole que je leur ai proposé, à savoir 10’ pour les deux premières séances, puis 15’ les 2 suivantes pour finir par 30’ pour les dernières. En moyenne 10 à 15 séances suffisent à déclencher une amélioration plus qu’intéressante! »
              </p>
              <div className="footer">
                <Img
                  className="avatar"
                  fixed={author.childImageSharp.fixed}
                  alt="Docteur Gérald Laval"
                />
                <span>Docteur Gérald Laval</span>
                <OrderButton
                  outline
                  text={"Commander<span class=\"hide-mobile\"> votre tapis</span>"}
                  href="https://www.lesmauxdedos.com/boutique/acupression?utm_source=cold&utm_medium=email&utm_campaign=mediafox"
                />
              </div>
            </Quote>
          </div>
        </QuoteContainer>
      </Container>
    </Wrapper>
  );
};

export default Verbatim;
