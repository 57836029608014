import staticQueryData from "../../public/static/d/3978990474.json";

/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React from 'react';
import Helmet from 'react-helmet';

function SEO(_ref) {
  var description = _ref.description,
      _ref$lang = _ref.lang,
      lang = _ref$lang === void 0 ? 'fr' : _ref$lang,
      _ref$meta = _ref.meta,
      meta = _ref$meta === void 0 ? [] : _ref$meta,
      title = _ref.title;
  var site = staticQueryData.data.site;
  var metaTitle = title || site.siteMetadata.title;
  var metaDescription = description || site.siteMetadata.description;
  return React.createElement(Helmet, {
    htmlAttributes: {
      lang: lang
    },
    title: metaTitle,
    meta: [{
      name: "description",
      content: metaDescription
    }, {
      property: "og:title",
      content: metaTitle
    }, {
      property: "og:description",
      content: metaDescription
    }, {
      property: "og:type",
      content: "website"
    }, {
      name: "twitter:card",
      content: "summary"
    }, {
      name: "twitter:title",
      content: metaTitle
    }, {
      name: "twitter:description",
      content: metaDescription
    }].concat(meta)
  });
}

export default SEO;