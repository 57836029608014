import styled from 'styled-components';
import { rem, device } from '../../utils';
import HoverMask from '../../images/common/HoverMask.svg';
export var Wrapper = styled.div.withConfig({
  componentId: "sc-1e2wlor-0"
})(["padding:", " 0 0 0;margin-bottom:50px;background:linear-gradient(180deg,rgba(245,245,245,1) 0%,rgba(255,255,255,1) 100%);"], rem(90));
export var Container = styled.div.withConfig({
  componentId: "sc-1e2wlor-1"
})(["text-align:center;"]);
export var Grid = styled.div.withConfig({
  componentId: "sc-1e2wlor-2"
})(["display:flex;justify-content:center;align-items:flex-start;flex-wrap:wrap;margin:0 -15px ", " -15px;@media ", "{margin:0 0 ", " 0;}"], rem(70), device.tablet, rem(70));
export var Item = styled.div.withConfig({
  componentId: "sc-1e2wlor-3"
})(["position:relative;flex:0 0 50%;margin-top:5px;text-align:center;height:auto;@media ", "{flex:1 1 33%;margin-top:15px;&:before{content:'';position:absolute;top:0;left:0;right:0;bottom:0;z-index:0;background:url(", ") center center no-repeat;background-size:contain;transition:transform .3s ease-out;transition-delay:.1s;transform:scale(0);}&:hover{&:before{transform:scale(1);}}}"], device.tablet, HoverMask);
export var ItemContent = styled.div.withConfig({
  componentId: "sc-1e2wlor-4"
})(["margin:0px auto;padding:10px 15px;box-sizing:border-box;position:relative;h4{font-size:", ";}p{font-size:", ";}@media ", "{margin:0px auto;padding:30px 40px;max-width:300px;z-index:1;}"], rem(18), rem(16), device.tablet);
export var CoverWrapper = styled.div.withConfig({
  componentId: "sc-1e2wlor-5"
})(["margin-top:", ";width:100%;position:relative;@media screen and ", " and (max-width:1500px){width:auto;margin-left:-30px;margin-right:-30px;}.cover{background-color:#d3d3d3;}"], rem(130), device.tablet);
export var CoverBubble = styled.div.withConfig({
  componentId: "sc-1e2wlor-6"
})(["position:absolute;bottom:0;left:0;right:0;background:url(", ") top center no-repeat;background-size:cover;padding:10px 20px 0 20px;text-align:center;h3{font-size:", ";line-height:1.3em;@media ", "{margin-bottom:0;}}@media ", "{top:", ";right:", ";bottom:auto;left:auto;text-align:left;background-position:center;background-size:contain;padding:60px 40px;}"], HoverMask, rem(18), device.tabletDown, device.tablet, rem(30), rem(30));